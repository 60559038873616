import "../styles/custom.scss";

import { Button } from "react-bootstrap";
import RLink from "../components/RLink";
import React from "react";
import Seo from "../components/Seo";
import WavesHeaderLayout from "../components/WavesHeaderLayout";

export default function Thanks() {
  return (
    <WavesHeaderLayout header={<Impl />}>
      <Seo title="Thanks" />
    </WavesHeaderLayout>
  );
}

function Impl() {
  return (
    <div className="d-flex flex-column align-items-center">
      <p className="lead">Thanks! We&rsquo;ll be in touch in the next day or two.</p>
      <Button
        variant="secondary"
        size="lg"
        as={RLink}
        to="/"
        className="mt-2 mb-5 px-5"
        style={{ maxWidth: 300 }}
      >
        Take me home
      </Button>
    </div>
  );
}
